import React, { useState, useEffect } from "react";


export default function Input({ onSend, status }) {
  const [text, setText] = useState("");

  const handleInputChange = e => {
    setText(e.target.value);
  };

  const handleSend = e => {
    e.preventDefault();
    onSend(text);
    setText("");
  };

  const randomPlaceholder = () => {
    const greetings = [
      "Tell me about yourself?", 
      "Where have you worked?", 
      "How do you lead?", 
      "What products have you built?",
      "What do you write about?",
      "Do you have change management experience?"
    ];
    const index = Math.floor(Math.random() * greetings.length);
    return `Enter your message here (e.g. ${greetings[index]})`;
  };

  return (
    <div className="input">
      <form onSubmit={handleSend}>
        <input
          type="text"
          onChange={handleInputChange}
          value={text}
          placeholder={randomPlaceholder()}
        />
        <button disabled={status == 'in_progress'} >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 500 500"
          >
            <g>
              <g>
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </g>
          </svg>
        </button>
      </form>
    </div>
  );
}
