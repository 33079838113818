import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import BotMessage from "./components/BotMessage";
import UserMessage from "./components/UserMessage";
import Messages from "./components/Messages";
import Input from "./components/Input";

import API from "./ChatbotAPI";
import DEBUG from "./Debug";

import "./styles.css";
import Header from "./components/Header";

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [status, setStatus] = useState("ready");

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(async () => {
    async function loadWelcomeMessage() {
      setMessages([
        <BotMessage
          key="0"
          isWelcome={true}
          fetchMessage={() => {} } //do nothing as it's a baked in message
        />
      ]);
    }
    loadWelcomeMessage();
    // setThreadId(await API.CreateThread())
  }, []);

  const send = async text => {
    setStatus('in_progress');
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={
          async () => {
            var localThreadId = threadId
            if (!localThreadId) {
              localThreadId = await API.CreateThread();
              setThreadId(localThreadId);
            }
            await API.SendMessage(text, localThreadId);
            const runId = await API.RunThread(localThreadId);
            DEBUG.log(`run id: ${runId}`);
            await sleep(1000);
            while (await API.RunStatus(localThreadId, runId) == 'in_progress') {
              await sleep(1000);
            }
            setStatus('ready');
            return await API.GetChatbotResponse(text, localThreadId);
          }
        }
      />
    );
    setMessages(newMessages);
  };

  return (
    <div className="chatbot">
      <Header />
      <Messages messages={messages} />
      <Input onSend={send} status={status} />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Chatbot />, rootElement);
