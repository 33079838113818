const DEBUG = {
  log: (obj) => {
    if (false)
      console.log(obj);
  },



};

export default DEBUG;