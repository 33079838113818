import DEBUG from "./Debug";

// const HOST = 'http://localhost:8080/';
const HOST = 'https://vincentchu.com/api/';

function removeOccurrences(stringsArray, destinationString) {
  let resultString = destinationString;
  stringsArray.forEach(str => {
    const regex = new RegExp(str, 'g'); // 'g' for global replacement
    resultString = resultString.replace(regex, '');
  });
  return resultString;
}

// Define a function that performs the fetch with a timeout and retries if it fails
const fetchWithTimeoutAndRetry = async (url, initObj = undefined, timeout = 15000, retries = 3) => {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
          reject(new Error('Request timed out'));
        }, timeout);

        fetch(url, initObj)
          .then(response => {
            clearTimeout(timer);
            if (response.ok) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(err => {
            clearTimeout(timer);
            reject(err);
          });
      });
      return response; // Return the response if fetch is successful
    } catch (error) {
      console.error(`Attempt ${attempt + 1} failed:`, error.message);
      // If it's the last attempt, throw the error
      if (attempt === retries - 1) throw error;
    }
  }
};


const API = {
  CreateThread: async () => {
    try {
      const response = await fetchWithTimeoutAndRetry(HOST + "create_thread");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      // Handle errors, such as network issues, or the server not responding
      console.error("Fetching data failed:", error);
      // You can reject with an error message or handle it differently
      return `Error fetching response: ${error.message}`;
    }
  },

  SendMessage: async(message, threadId) => {
    try {
      const response = await fetchWithTimeoutAndRetry(HOST + 'add_user_message', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              message: message,
              thread_id: threadId,
          }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      DEBUG.log(responseData); // Log or handle the response data as needed
      return responseData;
  } catch (error) {
      console.error('Error:', error);
      return { error: error.message };
  }

  },

  RunThread: async (threadId) => {
    try {
      const response = await fetchWithTimeoutAndRetry(HOST + `run_thread?thread_id=${threadId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      DEBUG.log(responseData); // Log or handle the response data as needed
      return responseData;
    } catch (error) {
      // Handle errors, such as network issues, or the server not responding
      console.error("Fetching data failed:", error);
      // You can reject with an error message or handle it differently
      return `Error fetching response: ${error.message}`;
    }

  },

  RunStatus: async (threadId, runId) => {
    try {
      const response = await fetchWithTimeoutAndRetry(HOST + `run_status?thread_id=${threadId}&run_id=${runId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      DEBUG.log(responseData); // Log or handle the response data as needed
      return responseData;
    } catch (error) {
      // Handle errors, such as network issues, or the server not responding
      console.error("Fetching data failed:", error);
      // You can reject with an error message or handle it differently
      return `Error fetching response: ${error.message}`;
    }
  },

  GetChatbotResponse: async (message, threadId) => {
    // Assuming you want to fetch data and then do something with it
    // If the API call is necessary, make sure to handle it properly
    // This example assumes you're fetching data but then returning a simple echo message
    try {
      const response = await fetchWithTimeoutAndRetry(HOST + `get_last_message?thread_id=${threadId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // If you want to use the response data, you can do so here
      // const data = await response.json();
      // return `Received data: ${JSON.stringify(data)}`;
      // console.log("Received from GetChatbotResponse");
      const responseJson = await response.json();
      DEBUG.log(responseJson);
      const annotations = responseJson?.data?.[0]?.content?.[0]?.text?.annotations ?? [];
      const annotationsTexts = annotations.map( (a) => a.text );
      const responseMessage = responseJson?.data?.[0]?.content?.[0]?.text?.value ?? 'default value';
      const responseMessageWithoutAnnotations = removeOccurrences(annotationsTexts, responseMessage);
      return responseMessageWithoutAnnotations;
    } catch (error) {
      // Handle errors, such as network issues, or the server not responding
      console.error("Fetching data failed:", error);
      // You can reject with an error message or handle it differently
      return `Error fetching response: ${error.message}`;
    }
  },
};

export default API;
